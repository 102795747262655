import * as dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
const utc = require("dayjs/plugin/utc");
dayjs.extend(relativeTime);
dayjs.extend(utc);

/**
 * Format date to readable format.
 * @param date Date to format.
 */
export const format = date => {
  return dayjs(date)
    .locale(require("dayjs/locale/fr"))
    .format("DD MMMM YYYY à HH:mm");
};

/**
 * Format birth date as "DD/MM/YYYY" for registration.
 * @param date Date to format.
 */
export const formatBirthDate = date => {
  return dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY");
};

/**
 * Format date to readable format (month).
 * @param date Date to format.
 */
export const formatMonth = date => {
  return dayjs(date)
    .locale(require("dayjs/locale/fr"))
    .format("MMMM YYYY");
};

/**
 * Extract year and month from date.
 * @param date Date to extract.
 */
export const extractYearMonth = date => {
  return dayjs(date).format("YYYY-MM");
};

/**
 * Get full date from year and month.
 * @param date Year month to transform.
 */
export const fullDateFromYearMonth = date => {
  return dayjs.utc(date, "YYYY-MM").format();
};

export default {
  format,
  formatBirthDate,
  formatMonth,
  extractYearMonth,
  fullDateFromYearMonth
};
