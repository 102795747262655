import { axios } from "@/utils/api/index";

/**
 * Get all directories by 100.
 * @page page Current page.
 * @return {Promise<AxiosResponse<any>>}
 */
export const getAll = page => {
  return axios().get("/directory/all", { params: { page: page } });
};

/**
 * Get one directory by id (UUID).
 * @param id Directory id (UUID).
 * @return {Promise<AxiosResponse<any>>}
 */
export const getOne = id => {
  return axios().get("/directory/get/" + id);
};

/**
 * Publication validation.
 * @param id Directory ID (UUID).
 * @param accepted Registration accepted or not.
 * @param reason If refuse, provide an reason.
 * @return {Promise<AxiosResponse<any>>}
 */
export const publicationValidation = (id, accepted, reason) => {
  return axios().patch("/directory/validation", {
    id,
    accepted,
    reason
  });
};

/**
 * Safe delete one directory by ID (UUID).
 *
 * @param id Directory ID (UUID).
 * @return {Promise<AxiosResponse<any>>}
 */
export const deleteOne = id => {
  return axios().delete("/directory/delete/" + id);
};

export default { getAll, getOne, publicationValidation, deleteOne };
