<template>
  <div>
    <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Toutes les publications
    </h2>
    <div
      v-if="directories"
      class="w-full mb-8 overflow-hidden rounded-lg shadow-xs"
    >
      <div class="w-full overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <thead>
            <tr
              class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
            >
              <th class="px-4 py-3">Titre</th>
              <th class="px-4 py-3">Type</th>
              <th class="px-4 py-3">Validation</th>
              <th class="px-4 py-3">Date de création</th>
            </tr>
          </thead>
          <tbody
            class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
          >
            <tr
              v-for="(dir, index) in directories"
              :key="index"
              class="text-gray-700 dark:text-gray-400"
            >
              <router-link
                :to="{
                  name: 'Directory',
                  params: { id: dir.id, directory: dir }
                }"
                class="px-4 py-3 text-sm td-router-link"
              >
                {{ dir.title }}
              </router-link>
              <td class="px-4 py-3 text-sm">
                {{ dir.type.fr_content }}
              </td>
              <td class="px-4 py-3 text-xs">
                <span
                  v-if="dir.admin_validated === 1"
                  class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100"
                >
                  Validé
                </span>
                <span
                  v-else-if="dir.admin_validated === 0"
                  class="px-2 py-1 font-semibold leading-tight text-orange-700 bg-orange-100 rounded-full dark:text-white dark:bg-orange-600"
                >
                  En attente
                </span>
                <span
                  v-else-if="dir.admin_validated === 2"
                  class="px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-full dark:text-red-100 dark:bg-red-700"
                >
                  Refusé
                </span>
              </td>
              <td class="px-4 py-3 text-sm">
                {{ dateTransform(dir.created_at) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800"
      >
        <!-- Pagination -->
        <span class="flex col-span-4 mt-2 sm:mt-auto sm:justify-end">
          <nav aria-label="Table navigation">
            <ul class="inline-flex items-center">
              <li>
                <button
                  class="px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple"
                  aria-label="Previous"
                  @click="changePagination(true)"
                >
                  <svg
                    aria-hidden="true"
                    class="w-4 h-4 fill-current"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </li>
              <li class="px-3 py-1 rounded-md">
                {{ currentPage + 1 }}
              </li>
              <li>
                <button
                  class="px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-purple"
                  aria-label="Next"
                  @click="changePagination(false)"
                >
                  <svg
                    class="w-4 h-4 fill-current"
                    aria-hidden="true"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import directory from "@/utils/api/directory";
import date from "@/utils/date";

export default {
  data() {
    return {
      currentPage: 0,
      directories: null
    };
  },
  mounted() {
    this.fetchDirectories();
  },
  methods: {
    dateTransform: date.format,
    fetchDirectories() {
      directory.getAll(this.$data.currentPage).then(res => {
        this.$data.directories = res.data;
      });
    },
    changePagination(isLess) {
      // update page counter and call api
      if (isLess && this.$data.currentPage !== 0) {
        this.$data.currentPage--;
        this.fetchDirectories();
      } else if (!isLess && this.$data.directories.length > 99) {
        this.$data.currentPage++;
        this.fetchDirectories();
      }
    }
  }
};
</script>
